@import "../../responsive";

.footer-heading {
  font-family: "CaviaDreams";
  font-weight: bolder;
  font-size: 0.9rem;
}
.desc-sub {
  margin-top: 1.5rem;
  font-family: "CaviaDreams";
  font-size: 0.85rem;
}

.desc-sub span {
  @extend .footer-heading;
}
.desc {
  font-family: "CaviaDreams";
  font-size: 0.9rem;
}

.modal-header {
  padding: 0.7rem 1rem;
  padding: 0px;
  border: none;
  position: absolute;
  right: 10px;
  z-index: 999;
}

.modal-body {
  padding: 1.2rem;
}

.modal-dialog {
  margin: 15rem auto !important;
}

.made-company {
  font-weight: bold;
  color: #222;
  text-decoration: none;
}

.made-company:hover {
  text-decoration: none;
}
.footer {
  font-family: "CaviaDreams";
  min-width: 18em;
  color: #6c757d;
  text-align: right;
  position: fixed;
  bottom: 0.2%;
  right: 0%;
  margin-right: 3.6vw;
  display: flex;
  grid-gap: 5px;
  z-index: 9999;
  @include respond-to(handhelds) {
    min-width: 1em;
  }

  .message {
    width: 90%;
    font-size: 0.9rem;
    color: #fff;
    position: relative;
    @include respond-to(handhelds) {
      display: none;
    }
    .tooltip-1 {
      width: 100%;
      height: 100%;
      padding-top: 0.8em;
      background-color: rgb(37, 37, 37);
      padding-right: 1em;
      position: absolute;
      transition: 2s;
    }
    .tooltip-2 {
      background-color: rgb(37, 37, 37);
      width: 100%;
      height: 100%;
      padding-top: 0.8em;
      padding-right: 1em;
      position: absolute;
      transition: 2s;
    }
  }
  .cr-logo {
    width: 10%;
    font-size: 2rem;
    cursor: pointer;
    color: rgb(48, 48, 48);
  }
}

.tooltip-inner {
  max-width: 100% !important;
}
