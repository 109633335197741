@import "../../_responsive.scss";

.title-page {
  color: #fff;
  background: #000000;
  position: absolute;
  top: 0%;
  left: 0%;
  height: 100%;
  width: 100%;
  font-family: sans-serif;
  overflow-y: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @include respond-to(mid-screens) {
    flex-direction: column;
  }

  .architecture {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      margin: 0;
    }
    .architecture-page {
      color: #fff;
      font-size: 250%;
      display: flex;
      justify-content: center;
      @include respond-to(handhelds) {
        font-size: 150%;
      }
    }
  }
  .architecture.active {
    background: white !important;
    a {
      width: 100%;
      height: 100%;
      transition: 0s !important;
    }
  }

  .engineering {
    @extend .architecture;
    @include respond-to(mid-screens) {
      width: 100%;
    }
    .engineering-page {
      @extend .architecture-page;
    }
    a {
      margin: 0;
    }
  }
  .engineering.active {
    @extend .architecture.active;
  }
  .divider {
    background: #969696;
    width: 0.1em;
    height: 6.5rem;
    animation: mydivider1;
    animation-duration: 1s;
    @include respond-to(mid-screens) {
      width: 6.5rem;
      height: 0.8px;
    }
  }
  @keyframes mydivider1 {
    from {
      height: 0rem;
    }
    to {
      height: 6.5rem;
    }
  }

  .engineering-page {
    width: 100%;
    height: 100%;
  }

  .architecture-page {
    @extend .engineering-page;
  }

  .img-overlay {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }

  .title-image {
    @include respond-to(handhelds) {
      width: 12rem;
    }
    animation-name: example;
    animation-duration: 4s
  }

  @keyframes example {
    from {}
    to {transform: scale(1.2);
      transition: transform 2s ease;}
  }

  //media queries //

  @media only screen and (max-width: $break-large) {
    img {
      width: 80%;
    }
    .divider {
      animation: mydivider2;
      animation-duration: 1s;
    }
  }
  @keyframes mydivider2 {
    from {
      width: 0rem;
    }
    to {
      width: 6.5rem;
    }
  }

  @media only screen and (max-width: $break-middle) {
    .title-page {
      .architecture {
        img {
          width: 60%;
        }
      }
    }
  }
}
