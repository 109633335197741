@import "../../responsive";
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300&display=swap");

.company-logo {
  min-height: 8rem;
  width: 100vw;
  margin-bottom: 10px;
  img {
    margin-top: 20px;
    margin-left: 3.6vw;
    width: 9rem;
    height: 3.5rem;
    @include respond-to(mid-screens) {
      margin-left: 3.6vw;
      width: 6.5rem;
      height: 2.5rem;
    }
  }
}

.header-wrapper {
  position: relative;
  z-index: 999;
}

#company-name {
  text-transform: uppercase;
}

.toogle-company {
  display: flex;
  margin-left: 3.6vw;
  margin-top: 18px;
  a {
    text-decoration: none;
    display: flex;
    flex-direction: row;
  }
}
.toogle-text {
  padding-top: 3px;
  color: #000;
  font-family: "CaviaDreams";
  font-size: 13px;
  padding-left: 10px;
}

.toogle-bar {
  background: #000;
  height: 25px;
  width: 1.5px;
}

#social-media {
  float: right;
  margin-top: 15px;
  margin-right: 3.6vw;
  .s-icons {
    list-style: none;
    display: inline-block;
    padding: 12px 0px 12px 24px;
    font-size: 1.1rem;
    color: rgb(14, 14, 14);
    span {
      a {
        color: #000;
      }
    }
    @include respond-to(mid-screens) {
      font-size: 1.2rem;
    }
  }
  @include respond-to(mid-screens) {
    padding: 0px;
  }
}
