@import "../../../responsive";

.work-wrapper {
  @include respond-to(wide-screens) {
    width: 94.5%;
    position: absolute;
    top: 47.2%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.ck.ck-balloon-panel {
  z-index: 10500 !important;
}

.main-container {
  @include respond-to(wide-screens) {
    width: 82%;
    height: 25rem;
    position: absolute;
    top: 47.2%;
    left: 50%;
    z-index: 1000;
    transform: translate(-50%, -50%);
    @include respond-to(xwide-screens) {
      height: 50vh;
    }
  }
  @include respond-to(mid-screens) {
    width: 100%;
    height: 35em;
  }
  @include respond-to(handhelds) {
    width: 90%;
    height: 35em;
  }
  @media all and (max-width: 654px) {
    width: 90%;
    margin: 20px auto;
  }
}

.gallery-main {
  width: 75%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @include respond-to(wide-screens) {
    width: 100%;
  }
  @include respond-to(mid-screens) {
    margin-top: 1em;
  }
  @include respond-to(handhelds) {
    width: 90%;
  }
}
.gallery {
  flex: 2;
  width: 100%;
  display: flex;
  @include respond-to(mid-screens) {
    flex-direction: column-reverse;
  }
}

.gallery-info {
  flex: 1;
  display: grid;
  align-items: center;
  padding-left: 1em;
  @include respond-to(mid-screens) {
    padding-left: 0em;
  }
  .work-info {
    text-align: right;
    @include respond-to(mid-screens) {
      padding: 60px 20px 60px 20px;
      text-align: center;
    }
    h4 {
      font-family: "CaviaDreamsBold";
      font-size: 20px;
      text-transform: capitalize;
    }
    p {
      font-family: "CaviaDreams";
      font-size: 0.8rem;
      margin: 0px;
    }
  }
}
.gallery-info-images {
  flex: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.gallery-details-main {
  width: 75%;
  margin: 15px auto;
  display: flex;
  flex-direction: row;
  @include respond-to(wide-screens) {
    width: 93%;
  }
  @include respond-to(mid-screens) {
    flex-direction: column-reverse;
    margin-top: 1em;
    width: 85%;
  }
  @include respond-to(handhelds) {
    width: 99%;
  }

  .gallery-desc {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 1em;
    @include respond-to(mid-screens) {
      padding: 40px 20px 40px 20px;
    }
    .work-detail {
      .heading {
        font-size: 20px;
        font-family: "CaviaDreamsBold";
        padding-right: 5px;
        text-align: right;
        padding-left: 1em;
        margin-top: 1rem;
        @include respond-to(mid-screens) {
          text-align: center;
        }
      }

      .description {
        font-size: 0.8rem;
        font-family: "CaviaDreams";
        text-align: right;
        color: rgb(0, 0, 0);
        opacity: 0.8;
        max-height: 450px;
        overflow: auto;
        @include respond-to(mid-screens) {
          text-align: center;
        }
      }
    }
  }
  .gallery-detail {
    flex: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.ReactGridGallery {
  margin-top: 10px;
  width: 94%;
  height: auto;

  @include respond-to(mid-screens) {
    margin-left: 0px;
  }
}
.ReactGridGallery_tile {
  width: 24.6% !important;
  height: auto !important;
  cursor: pointer;
  margin: 0px !important;
  margin-bottom: 2px !important;
  margin-left: 2px !important;
  .ReactGridGallery_tile-viewport {
    width: unset !important;
    // height: 18vh !important;
    overflow: hidden;

    img {
      width: 100% !important;
      object-fit: cover;
    }
  }
}

.gallery img:hover {
  -webkit-filter: blur(2px);
  filter: blur(2px);
  transition: 0.4s ease-in-out;
  // pointer-events: none;
}

.img title {
  display: none !important;
}

.up-down-btn {
  width: 97%;
  @include respond-to(mid-screens) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @include respond-to(xwide-screens) {
    width: 95%;
  }
}

.up-down {
  display: flex;
  float: right;
  z-index: 999;
  width: 4.5em;
  height: 100% !important;
  @include respond-to(mid-screens) {
    padding: 0em;
    float: unset;
  }

  cursor: pointer;
}
button:disabled,
button[disabled] {
  color: rgb(187, 187, 187) !important;
}

.up {
  color: rgb(48, 48, 48);
  background: transparent;
  font-size: 1.3rem;
  border-width: 0px;
  border: 0px;
  margin-top: 0px;
  height: 100%;
  padding-left: 14px;
  div {
    transform: scaleX(1.5) !important;
  }
}

.down {
  color: rgb(48, 48, 48);
  background: transparent;
  font-size: 1.3rem;
  border-width: 0px;
  border: 0px;
  height: 100%;
  padding-right: 0px !important;
  div {
    transform: scaleX(1.5) !important;
  }
}

.up:focus {
  outline: 0;
}
.down:focus {
  outline: 0;
}
.back-grid {
  display: flex;
  flex-direction: row;
  height: 2em;
  position: relative;
  margin-bottom: 8px;
  padding-bottom: 20px;
  border-bottom: 1px solid #e2e2e2;
  @include respond-to(wide-screens) {
    width: 90%;
    margin: 0 auto;
  }
  @include respond-to(mid-screens) {
    width: 88%;
    left: unset;
    margin: 0 auto;
  }
  @include respond-to(handhelds) {
    width: 93%;
  }

  a {
    font-size: 13px;
    position: relative;
    top: 25%;
  }
}

.back {
  font-family: "CaviaDreams";
  font-size: 1rem;
  flex: 1;
  cursor: pointer;
}

.front {
  font-family: "CaviaDreams";
  flex: 1;
  cursor: pointer;
}

.front-main {
  display: flex;
  flex-direction: row-reverse;
  width: 70px;
  float: right;
  position: relative;
  top: 30%;
}

.grid-container {
  display: grid;
  grid-column-gap: 0px;
  grid-template-columns: auto auto;
  background-color: white;
  width: 22px;
}
.slider-container {
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 11px;
  border-top: none;
  border-bottom: none;
  border-left: 1px solid #000;
  border-right: 1px solid #000;
}

.grid-item {
  background-color: black;
  border: 1px solid #000;
  padding: 3px 3px 3px 3px;
  text-align: center;
  margin: 1px;
}

.rectangle-toggle-box {
  height: 10px;
  width: 17px;
  background-color: #fff;
  border: solid 1px;
  color: black;
}

.ReactGridGallery_tile:hover img {
  filter: blur(1px);
  transition: 0.2s;
}

.ReactGridGallery_custom-overlay {
  div {
    overflow: unset !important;
  }
}

.inlarge-icon {
  position: absolute;
  right: 4%;
  bottom: -3em;
}

//Media queries//

@media all and (min-width: 1600px) {
  .up-down {
    padding-right: 1.5em;
  }
}

@media all and (max-width: 1450px) {
  .ReactGridGallery_tile {
    .ReactGridGallery_tile-viewport {
      height: 7.5em !important;
    }
  }
}

@media all and (max-width: 1240px) {
  .ReactGridGallery_tile {
    .ReactGridGallery_tile-viewport {
      height: 7em !important;
    }
  }
}

@media all and (max-width: 700px) {
  .ReactGridGallery_tile {
    width: 32.5% !important;
    .ReactGridGallery_tile-viewport {
      height: 7em !important;
    }
  }
}

@media all and (max-width: 0px) {
  .ReactGridGallery_tile {
    .ReactGridGallery_tile-viewport {
      height: 6em !important;
    }
  }
}

@media all and (max-width: 500px) {
  .ReactGridGallery_tile {
    width: 49% !important;
    .ReactGridGallery_tile-viewport {
      height: 8em !important;
    }
  }
}
