@import "../../../responsive";

.home-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.root-content {
  width: 100%;
  height: 100%;
  margin: -1px auto;
  @include respond-to(mid-screens) {
    display: grid;
    place-items: center;
    width: 100%;
  }
  @include respond-to(xwide-screens) {
    width: 100%;
  }

  .company-content {
    padding: 16px;
    .title {
      text-align: right;
      font-size: 1.4rem;
      font-family: "CaviaDreamsBold";
      font-weight: bolder;
      max-height: 72px;
      overflow: auto;
      @include respond-to(mid-screens) {
        text-align: center;
        font-size: 17px;
      }
    }
    .description {
      text-align: right;
      padding-top: 10px;
      font-size: 0.8rem;
      font-family: "CaviaDreams";
      opacity: 0.8;
      max-height: 200px;
      overflow-y: auto;
      @include respond-to(mid-screens) {
        text-align: center;
        font-size: 0.8rem;
      }
    }
  }
}
.filler {
  width: 100%;
  height: 39px;
}
