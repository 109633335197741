@import "../../../../responsive";

.slider-part {
  flex: 2;
  height: 100%;
}

.detail-wrapper {
  width: 100%;
}
.detail-part {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  bottom: 15%;
  z-index: 999;
  padding: 0px;
  width: 100vw;
  @include respond-to(mid-screens) {
    justify-content: center;
  }

  .company-content {
    background-color: rgba(255, 255, 255, 0.9);
    margin-right: 4vw;
    border-radius: 5px;
    max-width: 680px;
    @include respond-to(mid-screens) {
      margin-right: 0px;
    }
    @include respond-to(handhelds) {
      max-width: 400px;
    }
  }
}
.item {
  background: transparent;
  height: 100%;
  z-index: 10;
  transition: transform 300ms ease 100ms;
  display: flex;
  overflow: hidden;
  object-fit: fit;
  @include respond-to(xwide-screens) {
    width: 100%;
    height: 100%;
  }

  div #img {
    width: 100vw;
    height: 100%;
    border-radius: 2px;
    cursor: pointer;
    background-size: cover;
    background-repeat: no-repeat;

    background-position: center;
  }
}

.player-main {
  width: 100%;
  min-height: 100vh;
  @include respond-to(mid-screens) {
    min-height: unset;
    height: 23.0583125em;
    margin-top: 21vh;
  }
  @include respond-to(handhelds) {
    min-height: unset;
    margin-top: 21vh;
    height: 17.2em;
    margin-left: -2px;
    display: flex;
    flex-direction: column;
  }
  .our-player-play-pause {
    @include respond-to(handhelds) {
      position: unset;
      transform: unset;
      padding-left: unset;
      justify-content: center;
    }
  }
  .player-ours {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255) !important;

    #y-player {
      height: 100% !important;
      width: 100% !important;
      div {
        iframe {
          width: 100vw;
          height: 100%;
        }
      }
    }
  }
}

.item.active {
  display: inline;
}

.company-content {
  padding: 15px;
}

.our-player-play-pause {
  display: inline-flex;
  position: absolute;
  bottom: 8%;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 5px;
  padding-left: 15px;
  @include respond-to(mid-screens) {
    bottom: 28%;
  }
  @include respond-to(handhelds) {
    bottom: 40%;
  }
}

.slider-btn-main {
  height: 32px;
  position: absolute;
  bottom: 8%;
  right: 0%;
  margin: 0 auto;
  margin-right: 4vw;
  display: flex;
  @include respond-to(mid-screens) {
    width: 100%;
    right: 0;
    display: grid;
    place-items: center;
    margin-right: 0px;
  }
  .slider-buttons {
    border-radius: 5px;
    display: inline-flex;
    height: 32px;
    flex: 1;
    @include respond-to(mid-screens) {
      position: unset;
    }
    background-color: rgba(255, 255, 255, 0.9);
    button:disabled,
    button[disabled] {
      color: rgb(187, 187, 187) !important;
    }
    button {
    }
    .number-text {
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 6;
    }
  }
}

.play {
  background-color: #fff;
  font-size: 23px;
  border-style: solid;
  border-width: 0px;
  &:hover {
    border-color: transparent transparent transparent #241c1c86;
  }
  background: transparent;
  cursor: pointer;
  @include respond-to(handhelds) {
    font-size: 22px;
  }
}

.pause {
  margin-top: 11px;
  margin-right: -11px;
  height: 16px;
  border-style: double;
  border-width: 0px 0px 0px 15px;
  border-color: #202020;
  cursor: pointer;
  background: transparent;
  @include respond-to(handhelds) {
    border-width: 0px 0px 0px 12px;
    margin-left: 15px;
  }
  &:hover {
    border-color: transparent transparent transparent #241c1c86;
  }
}

a {
  padding: 0px 0px;
  // margin-top: 5px;
  font-family: "CaviaDreams";
  // color: #131313;
}

.previous {
  color: rgb(29, 29, 29);
  background: transparent;
  padding: 0px;
  border-width: 0px;
  border: 0px;
  width: 30px;
  font-size: 1.1rem;
  font-weight: 200;
}

.next {
  @extend .previous;
}

.view-btn {
  border: none;
  margin-top: 20px;
  width: 100px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  margin-bottom: 20px;

  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.slider-image {
  height: 100%;
  width: 100%;
  overflow: hidden;
  @include respond-to(mid-screens) {
    margin-left: 0px;
  }
  img {
    object-fit: cover;
    object-position: center top;
  }
}

.play:focus {
  outline: 0;
}
.pause:focus {
  outline: 0;
}
.previous:focus {
  outline: 0;
}
.next:focus {
  outline: 0;
}
