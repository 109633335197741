@import "../../../../responsive";

.work-slider-part {
  flex: 2;
  @include respond-to(handhelds) {
    flex: 1;
  }
}
.work-detail-part {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 1em;
  padding-right: 1em;
  @include respond-to(mid-screens) {
    padding-left: 0em;
    padding-bottom: 100px;
    padding-top: 40px;
  }
  @include respond-to(handhelds) {
    padding-top: 20px;
  }
}

.work-root-content {
  display: flex;
  min-height: 330px !important;

  @include respond-to(mid-screens) {
    flex-direction: column-reverse;
    overflow: auto;
  }
  @include respond-to(handhelds) {
    min-height: 300px;
  }
}
.work-root-content {
  display: flex;
  min-height: 330px !important;
  width: 93%;
  height: 75%;
  margin: 0px auto;

  @include respond-to(xwide-screens) {
    height: 55vh;
  }

  @include respond-to(mid-screens) {
    flex-direction: column-reverse;
    width: 100%;
    overflow: auto;
  }
  @include respond-to(handhelds) {
    min-height: 300px;
    width: 100%;
  }
}
.work-item {
  background: transparent;
  width: 100%;
  height: 100%;
  z-index: 10;
  transition: transform 300ms ease 100ms;
  display: flex;
  overflow: hidden;
  object-fit: fit;
  @include respond-to(mid-screens) {
    height: 90%;
  }

  div img {
    width: 100%;
    height: auto;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 2px;
    cursor: pointer;
    @include respond-to(wide-screens) {
      max-height: 62vh !important;
      object-fit: cover;
    }
  }
}

.description {
  font-size: 0.8rem;
  font-family: "CaviaDreams";
  text-align: right;
  color: rgb(0, 0, 0);
  opacity: 0.8;
  @include respond-to(mid-screens) {
    text-align: center;
  }
}

.custom-scrollbar {
  max-height: 360px !important;
  overflow: auto !important;
  padding: 5px;
}

.custom-scrollbar::-webkit-scrollbar {
  display: none;
}

// .custom-scrollbar::-webkit-scrollbar-thumb {
//   background-color: darkgrey;
// }

// .custom-scrollbar::-webkit-scrollbar-track {
//   background-color: lightgrey;
// }

.work-player-main {
  width: 94.5%;
  min-height: 62vh;
  margin: 0 auto;
  @include respond-to(mid-screens) {
    min-height: unset;
    height: 24.0583125em;
  }
  @include respond-to(handhelds) {
    min-height: unset;
    height: 15.2em;
  }
  .work-player-ours {
    width: 100%;
    height: 100%;
    background-color: rgb(240, 240, 240) !important;
    #work-y-player {
      height: 100% !important;
      width: 100% !important;

      iframe {
        width: 100%;
        height: 100%;
      }
    }
  }
}

#work-img {
  width: 100%;
  height: 100%;
  background-size: cover;
}

.work-player-main {
  margin-top: 10px;
}
.work-item.active {
  display: inline;
}

.work-our-player-play-pause {
  display: inline-flex;
  position: absolute;
}

.work-slider-btn-main {
  width: 95%;
  margin: 0 auto;
  position: relative;
  @include respond-to(mid-screens) {
    display: grid;
    place-items: center;
    width: 100%;
  }
  .work-slider-buttons {
    margin-top: 5px;
    float: right;
    height: 2em;
    width: 7.3em;
    @include respond-to(handhelds) {
      width: unset;
    }
    button:disabled,
    button[disabled] {
      color: rgb(187, 187, 187) !important;
    }
    .work-previous {
      padding-left: 0px;
      cursor: pointer;
    }
    .work-next {
      padding-right: 0px;
      color: pointer;
    }
    .work-number-text {
      font-size: 1rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: context-menu;
    }
  }
}

.work-play {
  background-color: #fff;
  font-size: 23px;
  border-style: solid;
  border-width: 0px;
  &:hover {
    border-color: transparent transparent transparent #241c1c86;
  }
  background: transparent;
  cursor: pointer;
  margin-top: 5px;
}

.work-pause {
  margin-top: 16px;
  margin-right: -11px;
  height: 16px;
  border-style: double;
  border-width: 0px 0px 0px 15px;
  border-color: #202020;
  cursor: pointer;
  background: transparent;
  &:hover {
    border-color: transparent transparent transparent #241c1c86;
  }
}

a {
  padding: 0px 0px;
  // margin-top: 5px;
  font-family: "CaviaDreams";
  // color: #131313;
}

.work-title {
  font-size: 20px;
  font-family: "CaviaDreamsBold";
  padding-right: 5px;
  text-align: right;
  padding-left: 1em;
  @include respond-to(mid-screens) {
    text-align: center;
  }
}

.work-previous {
  color: black;
  background: transparent;
  font-size: 40px;
  border-width: 0px;
  border: 0px;
  margin-top: -20px;
}

.work-next {
  background: transparent;
  color: black;
  font-size: 40px;
  border-width: 0px;
  margin-top: -20px;
}

.work-view-btn {
  border: none;
  margin-top: 20px;
  width: 100px;
  height: 35px;
  background-color: #fff;
  border: 1px solid #000;
  color: #000;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  margin-bottom: 20px;

  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.work-slider-image {
  height: 100%;
  width: 100%;
  margin-top: 10px;
  overflow: hidden;
  @include respond-to(mid-screens) {
    margin-left: 0px;
  }
  // img {
  //   width: 94.1% !important;
  //   height: 100%;
  // }
  img {
    width: 35em !important;
    height: 25em;
    object-fit: cover;
  }
}

.work-play:focus {
  outline: 0;
}
.work-pause:focus {
  outline: 0;
}
.work-previous:focus {
  outline: 0;
}
.work-next:focus {
  outline: 0;
}
