@import "../../../../responsive";

#introduction {
  max-height: 64vh;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  overflow: auto;
  @include respond-to(mid-screens) {
    min-height: 500px;
  }
}

.introduction-content {
  background: transparent;
  height: 80%;
  width: 100%;
  @include respond-to(mid-screens) {
    width: 90%;
  }

  .intro-description {
    font-size: 0.9rem;
    font-family: "CaviaDreams";
    text-align: left;
    padding-top: 0.7em;
    opacity: 0.6;
    padding-bottom: 3em;
    p {
      font-family: "CaviaDreams" !important;
      span {
        font-family: "CaviaDreams" !important;
      }
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: "CaviaDreams" !important;
      span {
        font-family: "CaviaDreams" !important;
      }
    }
  }
}

.title {
  font-size: 1.3rem;
  font-family: "CaviaDreamsBold";
  @include respond-to(handhelds) {
    text-align: center;
  }
}

.mission {
  font-size: 1.3rem;
  font-family: "CaviaDreamsBold";
  @include respond-to(handhelds) {
    text-align: center;
  }
}

.about-wrapper {
  @include respond-to(wide-screens) {
    width: 94.5%;
    position: absolute;
    top: 47.2%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.about-gallery-main {
  width: 75%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // border: 1px solid red;
  @include respond-to(wide-screens) {
    width: 100%;
  }
  @include respond-to(mid-screens) {
    margin-top: 1em;
  }
  @include respond-to(handhelds) {
    width: 90%;
  }
}

.about-gallery {
  flex: 2;
  width: 100%;
  display: flex;
  @include respond-to(mid-screens) {
    flex-direction: column-reverse;
  }
}

.about-gallery-info {
  flex: 1;
  display: grid;
  // align-items: center;
  padding-left: 1em;
  @include respond-to(mid-screens) {
    padding-left: 0em;
  } 
  .about-info {
    text-align: right;
    @include respond-to(mid-screens) {
      padding: 0px 20px 60px 20px;
      text-align: center;
    }
    h4 {
      font-family: "CaviaDreamsBold";
      font-size: 20px;
      text-transform: capitalize;
    }
    p {
      font-family: "CaviaDreams";
      font-size: 0.8rem;
      margin: 0px;
    }
  }
}
.about-gallery-info-images {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
