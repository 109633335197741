#introduction-details {
  height: 68vh;
}

.activities {
  height: 100%;
  width: 100%;
  padding-bottom: 20px;
  .activity {
    width: 100%;
    min-height: 40px;
    display: flex;
    flex-direction: column;

    .goback {
      font-size: 0.75rem;
      font-family: "CaviaDreams";
      padding: 5px 5px 5px 0px;
      border-bottom: 1px solid rgb(161, 161, 161);
      cursor: pointer;
      span {
        text-transform: capitalize;
      }
    }

    .activity-title {
      flex: 1;
      text-transform: uppercase;
      border-bottom: 1px solid rgb(161, 161, 161);
      padding: 5px;
      font-size: 1rem;
      font-family: "CaviaDreams";
      &span.active {
        color: red;
      }
      .activity-plus-minus {
        font-size: 1.8rem !important;
      }
      .activity-main-title {
        font-size: 1.2rem;
        padding-left: 3px;
        font-weight: bold;
      }
    }

    .author {
      font-size: 0.75rem;
      font-family: "CaviaDreams";
      padding-top: 0.6rem;
      padding-right: 5px;
      font-weight: bold;
    }

    .author2 {
      font-size: 0.9rem;
      font-family: "CaviaDreams";
      padding-left: 5px;
      font-weight: bold;
    }

    .date {
      font-size: 0.75rem;
      font-family: "CaviaDreams";
      padding-top: 0.6rem;
      font-weight: bold;
    }

    .details {
      border-bottom: unset !important;
      font-weight: bolder;
      padding-top: 20px;
      padding-bottom: 0px;
      padding-left: 13px;
    }
    .activity-description {
      padding: 5px;
      padding-left: 15px;
      flex: 1;
      font-size: 0.9rem;
      font-family: "CaviaDreams";
      color: rgb(70, 70, 70);
      display: none;
      max-height: 54vh;
      overflow-y: auto;
      overflow-x: hidden;

      .description {
        font-family: "CaviaDreams";
        text-align: left !important;
        padding-top: 20px;
        font-size: 0.9rem !important;
        padding-bottom: 50px;
        a {
          color: #000 !important;
        }
        p {
          font-size: 0.9rem !important;
          a {
            color: #000 !important;
          }
        }
        span {
          font-family: "CaviaDreams" !important;
          font-size: 0.9rem !important;
        }
        em {
          font-size: 0.9rem !important;
        }
      }

      .short-description {
        font-family: "CaviaDreams";
        text-align: left !important;
        max-height: 3rem;
        overflow: hidden;
        padding-top: 20px;
        p {
          margin: 0px;
          font-size: 1rem !important;
          span {
            display: inline-block;
            font-family: "CaviaDreams" !important;
            width: 98%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 1rem !important;
          }
        }
      }

      .readmore {
        float: right;
        border: none;
        background-color: #141414;
        color: #fff;
        padding: 4px 11px;
        font-size: 0.8rem;
      }
    }
  }
}

#plusminus {
  font-size: 32px;
  cursor: pointer;
  display: inline-block;
}

.activity div.active {
  display: block !important;
}

.img {
  width: 200px;
  height: 150px;
  float: left;
  background-color: rgb(206, 206, 206);
  margin: 5px 10px 0px 0px;
}

.below-title {
  padding-left: 14px;
  padding-bottom: 10px;
}

.activities {
  .not-found {
    width: 100%;
    min-height: 400px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    .not-found-content {
      img {
        width: 180px;
        opacity: 0.1;
      }
      .no-text {
        font-family: "CaviaDreams" !important;
        font-size: 1rem;
        font-weight: bold;
        color: #777;
        padding-top: 15px;
      }
    }
  }
}

.contactus {
  border: none;
  background-color: #000;
  color: #fff;
  padding: 5px 10px 5px 10px;
  font-size: 0.9rem;
  float: right;
}
